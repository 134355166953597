import React, { useState, useEffect } from 'react'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardFooter
} from 'reactstrap'
import _ from 'lodash'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from "filepond-plugin-image-preview"  
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import Moment from 'moment'

import TreatmentComponent from './components/TreatmentComponent'
import LargeXray from './components/LargeXray'
import ConfirmDelete from 'components/Indicator/Prompt'
import Typeahead from 'components/Typeahead'
import PerioChart from './PerioChart'
import DentalChart from './DentalChart'

import getDomainURL from 'utils/api'
import CurrencySettings from 'utils/currencies'

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"
registerPlugin( FilePondPluginImagePreview, FilePondPluginFileValidateType )

const General = ({
  updateMedicalRecord,
  onChangeMedicalRecordHOC,
  selectedMedicalRecord,
  doctors,
  treatments,
  newAssignTreatment,
  onChangeAssignTreatmentHOC,
  assignTreatmentToMedicalRecord,
  removeTreatmentFromMedicalRecord,
  medicines,
  newAssignMedicine,
  onChangeAssignMedicineHOC,
  assignMedicineToMedicalRecord,
  removeMedicineFromMedicalRecord,
  updateMedicalRecordTreatment,
  uploadXrayImages,
  removeXrayImages,
  medicalRecordDocuments,
  createMedicalRecordDocument,
  removeMedicalRecordDocument,
  selectedPatient
}) => {
  const [ selectedMedicine, updateSelectedMedicine ] = useState( 0 )
  const [ selectedTreatment, updateSelectedTreatment ] = useState( 0 )  
  const [ showConfirmSubmission, updateConfirmSubmission ] = useState( false )
  const [ showLargeXray, updateShowLargeXray ] = useState( false )
  const [ selectedXrayImage, updateSelectedXrayImage ] = useState( '' )
  const [ files, updateFiles ] = useState( [] )
  const [ medicalRecordFiles, updateMedicalRecordFiles ] = useState( [] )
  const [ showPerioChart, toggleShowPerioChart ] = useState( false )
  const [ showDentalChart, toggleShowDentalChart ] = useState( false )
  const [ toDeleteImage, updateToDeleteImage ] = useState( {} )
  const [ showConfirmDelete, updateShowConfirmDelete ] = useState( false )
  
  const updateMedicalRecordData = ( key, val ) => {
    let tmp = _.cloneDeep( selectedMedicalRecord )
    tmp[ key ] = val
    return onChangeMedicalRecordHOC( 'selectedMedicalRecord', tmp )
  }
  return (
    <Form onSubmit={ e => e.preventDefault()}>
      <b>Please select treatment and medicine first then fill up the form.</b>
      <FormGroup>
        <Label>Select treatment</Label>
        <Row>
          <Col md={ 9 } className='mb-1' >
            <Typeahead
              id={ 'generalTabTreatment' }
              multiple={ false }
              options={ treatments.map( treatment => ({ 
                  id: treatment.id.toString(),
                  label: treatment.id.toString(),
                  value: `${ treatment.name } - ${ CurrencySettings() }${ treatment.price }`
                }) )
              }
              onSelect={ val => {
                if( val && val.length > 0 ) {
                  updateSelectedTreatment( parseInt( val[ 0 ].id ) )
                  onChangeAssignTreatmentHOC( 'newAssignTreatment', {
                    medical_record_id: selectedMedicalRecord.id,
                    treatment_id: parseInt( val[ 0 ].id )
                  })
                } else {
                  updateSelectedTreatment( 0 )
                  onChangeAssignTreatmentHOC( 'newAssignTreatment', {} )
                }
              }}
              selectedValue={ _.find( treatments, { id: selectedTreatment } ) 
                ? [{
                  id: _.find( treatments, { id: selectedTreatment } ).id.toString(),
                  label: _.find( treatments, { id: selectedTreatment } ).id.toString(),
                  value: `${ _.find( treatments, { id: selectedTreatment } ).name } - ${ CurrencySettings() }${ _.find( treatments, { id: selectedTreatment } ).price }`
                }] : [] 
              } />
          </Col>
          <Col md={ 3 } className='mb-1' >
            <Button 
              className='d-flex'
              style={{ height: "100%" }}
              color="primary"
              onClick={() => {
                updateSelectedTreatment( 0 )
                assignTreatmentToMedicalRecord( newAssignTreatment )
              }}
              disabled={ selectedTreatment === 0 }>Submit</Button>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Label>Selected treatment</Label>
        {
          selectedMedicalRecord.medical_record_treatments.map(( item, index ) => {
            return (
              <TreatmentComponent  
                tabType='General' 
                key={ item.id }
                removeTreatmentFromMedicalRecord={ removeTreatmentFromMedicalRecord }
                item={ item }
                selectedMedicalRecord={ selectedMedicalRecord }
                index={ index }
                updateMedicalRecordData={ updateMedicalRecordData }
                updateMedicalRecordTreatment={ updateMedicalRecordTreatment } />
            )
          })
        }
      </FormGroup>
      <FormGroup>
        <Label>Select medicine prescribed</Label>
        <Row>
          <Col md={ 9 } className='mb-1' >
            <Typeahead
              id={ 'generalTabMedicine' }
              multiple={ false }
              options={ medicines.map( medicine => ({ 
                id: medicine.id.toString(),
                label: medicine.id.toString(),
                value: `${ medicine.name } - ${ CurrencySettings() }${ medicine.price_per_unit }`
              }) )
              }
              onSelect={ val => {
                if( val && val.length > 0 ) {
                  updateSelectedMedicine( parseInt( val[ 0 ].id ) )
                  onChangeAssignMedicineHOC( 'newAssignMedicine', {
                    medical_record_id: selectedMedicalRecord.id,
                    medicine_id: parseInt( val[ 0 ].id )
                  })
                } else {
                  updateSelectedMedicine( 0 )
                  onChangeAssignMedicineHOC( 'newAssignMedicine', {} )
                }
              }}
              selectedValue={ _.find( medicines, { id: selectedMedicine } ) 
                ? [{
                  id: _.find( medicines, { id: selectedMedicine } ).id.toString(),
                  label: _.find( medicines, { id: selectedMedicine } ).id.toString(),
                  value: `${ _.find( medicines, { id: selectedMedicine } ).name } - ${ CurrencySettings() }${ _.find( medicines, { id: selectedMedicine } ).price_per_unit }`
                }] : [] 
              } />
          </Col>
          <Col md={ 3 } className='mb-1' >
            <Button 
              className='d-flex'
              style={{ height: "100%" }}
              color="primary"
              onClick={() => {
                updateSelectedMedicine( 0 )
                assignMedicineToMedicalRecord( newAssignMedicine )
              }}
              disabled={ selectedMedicine === 0 }>Submit</Button>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Label>Medicines prescribed</Label>
        {
          selectedMedicalRecord.medicines.map( item => {
            return (
              <Card key={ item.id }>
                <CardBody>
                  <span className="mr-3">{ item.name }</span>
                  <span className="mr-3">{ `${ CurrencySettings() }${ item.price_per_unit }` }</span>
                  <Button 
                    color="danger"
                    onClick={() => {
                      removeMedicineFromMedicalRecord({
                        medical_record_id: selectedMedicalRecord.id,
                        medicine_id: item.id
                      })
                    }}>Remove</Button>
                </CardBody>
              </Card>
            )
          })
        }
      </FormGroup>
      <FormGroup>
        <Label>Upload X-ray image(s)</Label>
        <FilePond
          files={ files }
          allowMultiple={ true }
          allowFileTypeValidation={ true }
          allowImagePreview={ true }
          acceptedFileTypes={[ 'image/png', 'image/jpeg', 'image/jpg' ]}
          onupdatefiles={ fileItems => updateFiles( fileItems.map( fileItem => fileItem.file ) ) } />
        <div className="d-flex justify-content-end mt-4">
          <Button
            color='primary'
            disabled={ files.length < 1 }
            onClick={ () => {
              let tmpFileList = [] 
              files.map( file => {
                const reader = new FileReader()
                reader.onload = e => {
                  tmpFileList.push( e.target.result )
                  if( tmpFileList.length === files.length ){
                    let tmpPayload = {
                      id: selectedMedicalRecord.id,
                      xray_images: tmpFileList
                    }
                    uploadXrayImages( selectedMedicalRecord, tmpPayload )
                    updateFiles( [] )
                  }
                }
                reader.readAsDataURL( file )
              })
            }}>
            Submit</Button>
        </div>
      </FormGroup>
      <FormGroup>
        <Label>Uploaded X-ray image(s)</Label>
        {
          selectedMedicalRecord.xray_image.length > 0 && selectedMedicalRecord.xray_image.map( xray => (
            <Card key={ 'xray_' + xray.id } className='mt-3 d-flex' style={{ maxWidth: "min-content" }}>
              <CardBody>
                <img 
                  src={ `${ getDomainURL() }${ xray.url }` } 
                  style={{ 
                    height: "300px", 
                    width: "300px",
                    cursor: "pointer" }}
                  onClick={ () => {
                    updateSelectedXrayImage( xray.url )
                    updateShowLargeXray( true ) }}/>
              </CardBody>
              <CardFooter className='d-flex justify-content-end'>
                <Button
                  color='primary'
                  className='mr-2'
                  onClick={ () => {
                    updateSelectedXrayImage( `${ getDomainURL() }${ xray.url }` )
                    updateShowLargeXray( true ) }}>
                  View</Button>
                <Button
                  color='danger'
                  onClick={() => {
                    Promise.all([
                      updateToDeleteImage( xray )
                    ]).then(() => {
                      updateShowConfirmDelete( true )
                    })
                  }}>
                  Remove</Button>
              </CardFooter>
            </Card>  
          ))
        }
      </FormGroup>
      <FormGroup>
        <Label>Upload medical record document(s)</Label>
        <FilePond
          files={ medicalRecordFiles }
          allowMultiple={ false }
          allowFileTypeValidation={ true }
          allowImagePreview={ true }
          acceptedFileTypes={[ 'application/pdf' ]}
          onupdatefiles={ fileItems => updateMedicalRecordFiles( fileItems.map( fileItem => fileItem.file ) ) } />
        <div className="d-flex justify-content-end mt-4">
          <Button
            color='primary'
            disabled={ medicalRecordFiles.length < 1 }
            onClick={ () => {
              medicalRecordFiles.map( file => {
                const data = new FormData()
                data.append( 'document', file )
                createMedicalRecordDocument( selectedMedicalRecord.id, data )
                updateMedicalRecordFiles( [] )
              })
            }}>
            Submit</Button>
        </div>
      </FormGroup>
      <FormGroup>
        <Label>Uploaded medical record document(s)</Label>
        { 
          medicalRecordDocuments.map( doc => (
            <Card className='mb-1'>
              <CardBody>
                <Row className='m-0 flex-nowrap' >
                  <Input
                    disabled
                    type='text'
                    className='mr-2'
                    value={ doc.document_link } />
                  <Button
                    color='primary'
                    className='mr-2'
                    onClick={ () => window.open( doc.document_link ) }>
                    View</Button>
                  <Button
                    color='danger'
                    onClick={ () => {
                      removeMedicalRecordDocument( selectedMedicalRecord.id, doc.id ) 
                    }}>
                    Remove</Button>
                </Row>
              </CardBody>
            </Card>
          )) 
        }
      </FormGroup>
      <FormGroup>
        <Button 
          color="primary"
          onClick={() => {
            toggleShowPerioChart( true )
          }}>Perio charting</Button>
        <Button 
          color="primary"
          onClick={() => {
            toggleShowDentalChart( true )
          }}>Dental charting</Button>
        <PerioChart
          showPerioChart={ showPerioChart }
          toggleShowPerioChart={ toggleShowPerioChart }
          updateMedicalRecordData={ updateMedicalRecordData }
          selectedMedicalRecord={ selectedMedicalRecord } />
        <DentalChart
          showDentalChart={ showDentalChart }
          toggleShowDentalChart={ toggleShowDentalChart }
          updateMedicalRecordData={ updateMedicalRecordData }
          selectedMedicalRecord={ selectedMedicalRecord } />
      </FormGroup>
      <FormGroup>
        <Label>Complaints</Label>
        <Input 
          type="text"
          value={ selectedMedicalRecord.complaints }
          onChange={ e => {
            updateMedicalRecordData( 'complaints', e.target.value )
          }} />
      </FormGroup>
      <FormGroup>
        <Label>Observations</Label>
        <Input
          type="textarea"
          value={ selectedMedicalRecord.observations }
          onChange={ e => {
            updateMedicalRecordData( 'observations', e.target.value )
          }}
          rows="8" />
      </FormGroup>
      <FormGroup>
        <Label>Notes</Label>
        <Input
          type="textarea"
          value={ selectedMedicalRecord.notes }
          onChange={ e => {
            updateMedicalRecordData( 'notes', e.target.value )
          }}
          rows="8" />
      </FormGroup>
      {/* <FormGroup>
        <Label>Tooth chart</Label>
        <ToothChart
          data={ selectedMedicalRecord.tooth_charts }
          onChange={ val => {
            updateMedicalRecordData( 'tooth_charts', val )
          }} />
      </FormGroup> */}
      <FormGroup>
        <Label>Diagnosed by</Label>
        <Input
          type="select"
          disabled={ true }
          value={ selectedMedicalRecord.user_id }>
          <option></option>
          {
            doctors.map( item => {
              return <option key={ item.id } value={ item.id }>{ `${ item.salutation } ${ item.name }` }</option>
            })
          }
        </Input>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type={ 'checkbox' }
            checked={ selectedMedicalRecord.is_incomplete_treatment }
            onChange={ e => {
              updateMedicalRecordData( 'is_incomplete_treatment', e.target.checked )
            }} />
          Is incomplete treatment?
        </Label>
      </FormGroup>
      {/* if treatment is incomplete */}
      {/* <FormGroup className="mt-3">
        <Row>
          <Col md={ 3 }>
            <Input
              type="number"
              value={ selectedMedicalRecord.current_treatment_count }
              onChange={ e => {
                updateMedicalRecordData( 'current_treatment_count', e.target.value )
              }} />
          </Col>
          <Col md={ 1 }>
            <span style={{ fontSize: '24px' }}>/</span>
          </Col>
          <Col md={ 3 }>
            <Input
              type="number"
              value={ selectedMedicalRecord.total_treatment_count }
              onChange={ e => {
                updateMedicalRecordData( 'total_treatment_count', e.target.value )
              }} />
          </Col>
          <Col md={ 5 }></Col>
        </Row>
      </FormGroup> */}
      <FormGroup style={{ textAlign: 'right' }}>
        <Button 
          color="primary"
          className="mr-2"
          onClick={() => {
            updateMedicalRecord( selectedMedicalRecord )
            if(navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
              
            } else {
              // const data = JSON.stringify(selectedMedicalRecord, null, 2); 
              // const element = document.createElement('a');
              // const file = new Blob([data], {type: 'text/plain'});
              // element.href = URL.createObjectURL(file);
              // element.download = `${Moment().toISOString()}${selectedPatient.name}`;
              // document.body.appendChild(element);
              // element.click();
            }
          }}>Submit</Button>
        {/* <Button 
          color="success"
          onClick={() => {
            updateConfirmSubmission( true )
          }}>Confirm submission</Button> */}
      </FormGroup>
      <ConfirmDelete 
        showPromptModal={ showConfirmDelete }
        content={ 'Are you sure to remove the selected image?' }
        onClickYes={() => {
          removeXrayImages( selectedMedicalRecord, toDeleteImage.id ) 
          updateShowConfirmDelete( false )
        }}
        onClickNo={() => updateShowConfirmDelete( false )} />
      <LargeXray
        url={ selectedXrayImage }
        showLargeXray={ showLargeXray }
        updateShowXray={ updateShowLargeXray } />
    </Form>
  )
}

export default General